/**
 * Message from user
 */
export const CHAT_SELF: number = 0;

/**
 * Received message from server
 */
export const CHAT_RTN: number = 1;

/**
 * Notifications
 */
export const CHAT_NOTI: number = 2;