import React, {Component} from 'react';
import ChatLog from './component/chatcontent/ChatLog';
import VoiceInput from './component/input/VoiceInput';
import TextInput from './component/input/TextInput';
import ChatMessage from '../../api/ChatMessage';

import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Container
} from 'reactstrap';

import './chatbox.css';

interface Props {
    chatlog: Array<ChatMessage>,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    sendByTouch: (v: string) => void,
    inputRef: React.RefObject<HTMLInputElement>
}

interface State {
    isMicWinOpen: boolean
}

class AppPresenter extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.micInputWindow = this.micInputWindow.bind(this);
        this.closeMicInputWin = this.closeMicInputWin.bind(this);
        this.inputMicData = this.inputMicData.bind(this);
        (window as any).inputMicData = this.inputMicData;
        (window as any).closeMicInputWin = this.closeMicInputWin;
    }

    state: State = {
        isMicWinOpen: false
    }

    inputMicData(micstr: string) {
        this.closeMicInputWin();
        this.props.sendByTouch(micstr);
    }

    micInputWindow() {
        // Android Method 실행하기
        if((window as any).Android != undefined) {
            (window as any).Android.openMic();
        }

        // MicInputWindow 열기
        this.setState({
            isMicWinOpen: true
        });
    }

    closeMicInputWin() {
        this.setState({
            isMicWinOpen: false
        });
    }

    render() {
        const self = this;
        return (
            <Container fluid={true} className="chat-container">
                <Row className="chat-row">
                    <Col xs="12" className="chat-col p-0">
                        <Card>
                            <CardBody id="msgbox"
                                className="d-flex flex-column msg_card_body">
                                <ChatLog
                                    list={this.props.chatlog} />
                            </CardBody>
                            <CardFooter>
                                {
                                    (function() {
                                        if(self.state.isMicWinOpen) {
                                            return (
                                                <VoiceInput
                                                    micCancel={self.closeMicInputWin}
                                                />
                                            )
                                        }
                                        else {
                                            return (
                                                <TextInput
                                                    inputRef={self.props.inputRef}
                                                    micInputWindow={self.micInputWindow}
                                                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => self.props.onKeyDown(e)}
                                                    sendByTouch={self.props.sendByTouch}
                                                />
                                            )
                                        }
                                    })()
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AppPresenter;