// action: 어떤 변화가 일어나야 할지 알려주는 객체
// 액션 자체는 그냥 객체임
// 액션은 스토어에 데이터를 넣는 유일한 방법

// action 타입 정의
export const SETFIRSTRUNON = 'SETFIRSTRUNON';
export const SETFIRSTRUNOFF = 'SETFIRSTRUNOFF';
export const SHOWHOWTO = 'SHOWHOWTO';
export const HIDEHOWTO = 'HIDEHOWTO';

export interface ActionUserInfo {
    firstrun: boolean,
    showHowto: boolean
}

interface SetFirstRunOnAction {
    type: typeof SETFIRSTRUNON,
    firstrun: boolean
}

interface SetFirstRunOffAction {
    type: typeof SETFIRSTRUNOFF,
    firstrun: boolean
}

interface SetShowHowtoAction {
    type: typeof SHOWHOWTO,
    showHowto: boolean
}

interface SetHideHowtoAction {
    type: typeof HIDEHOWTO,
    showHowto: boolean
}

export type RootAction =
    SetFirstRunOnAction
    | SetFirstRunOffAction
    | SetShowHowtoAction
    | SetHideHowtoAction;

// action 정의
// 액션이 dispatch에 의해 store로 전달되면
// store 내의 reducer에 의해 store에 저장됨
function setFirstRunOn(): SetFirstRunOnAction {
    return {
        type: SETFIRSTRUNON,
        firstrun: true
    }
}

function setFirstRunOff(): SetFirstRunOffAction {
    return {
        type: SETFIRSTRUNOFF,
        firstrun: false
    }
}

function showHowto(): SetShowHowtoAction {
    return {
        type: SHOWHOWTO,
        showHowto: true
    }
}

function hideHowto(): SetHideHowtoAction {
    return {
        type: HIDEHOWTO,
        showHowto: false
    }
}

// 액션생산자: 액션을 만드는 함수 혹은 변수
// 나중에 dispatch를 해야 액션을 보낸다
export const actionCreator = {
    setFirstRunOn,
    setFirstRunOff,
    showHowto,
    hideHowto
};