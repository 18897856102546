class ChatMessage {
    /**
     * Message itself
     */
    private msg: string = "";

    /**
     * Message type, defined in ChatType Class
     */
    private type: number = -1;

    constructor(msg: string, type: number) {
        this.msg = msg;
        this.type = type;
    }

    getMessage() { return this.msg; }
    getUserType() { return this.type; }
    toJson() {
        return {type: this.type, msg: this.msg}
    }
}

export default ChatMessage;