import React, {Component, Fragment} from 'react';

import {CHAT_SELF, CHAT_RTN, CHAT_NOTI} from '../../../../api/ChatType';
import ChatMessage from '../../../../api/ChatMessage';
import "./chat.css";

import {
    Row, Col
} from 'reactstrap';

interface Props {
    list: Array<ChatMessage>
}

class ChatLog extends Component<Props> {
    render() {
        return (
            <Fragment>
            {
                this.props.list.map((v: ChatMessage, i: number) => {
                    if(v.getUserType() === CHAT_SELF) {
                        return (
                            <Fragment key={i}>
                                <div className="d-flex justify-content-end msg_container_upper">
                                    <div className="msg_container_send">
                                        {v.getMessage()}
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                    else if(v.getUserType() === CHAT_RTN) {
                        return (
                            <Fragment key={i}>
                                <Row className="ansload">
                                    <Col xs="12">
                                        <img src={require('./loading.gif')} style={{width: "40px"}} />
                                    </Col>
                                </Row>
                                <Row className="anscont" style={{display: "none"}}>
                                    <Col xs="12" className="chat-col">
                                        <img alt="" src={require("./icon.png")}
                                            className="rounded-circle user_img_msg"
                                            style={{float: "left"}} />
                                        <div>
                                            <div>
                                                <span className="msg_rtn_name">
                                                    ChatBot
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-start msg_container_upper">
                                                <div className="msg_container"
                                                    dangerouslySetInnerHTML={{__html: v.getMessage()}}>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    }
                    else if(v.getUserType() === CHAT_NOTI) {
                        return (
                            <Fragment key={i}>
                                <Row>
                                    <Col xs="12" className="msg_container_noti"
                                        dangerouslySetInnerHTML={{__html: v.getMessage()}}>
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    }
                    else return null;
                })
            }
            </Fragment>
        )
    }
}

export default ChatLog;