import SockJS from 'sockjs-client';
import {Stomp, CompatClient, messageCallbackType} from '@stomp/stompjs';

class StompComm {
    // stomp variable
    private topic: string;
    
    // added at construction
    private session: string;
    private socket: WebSocket;
    
    public client: CompatClient;

    constructor(url: string, session: string, subsUpdateMethod: messageCallbackType) {
        this.topic = "/topic/testchat/";

        this.session = session;

        // Establish connection with sockjs-client
        this.socket = new SockJS(url);

        // Create STOMP client with stompjs
        this.client = Stomp.over(this.socket);

        // Connect to server and subscribe wschannel
        this.client.connect({}, () => {
            this.client.subscribe(this.topic+this.session, subsUpdateMethod);
        });
    }

    /**
     * 
     * @param topicUrl note that we need session after topic url string. default value for topicUrl is /topic/testchat/
     */
    changeTopic(topicUrl: string) {
        this.topic = topicUrl;
    }
}

export default StompComm;