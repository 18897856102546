import React, {Fragment} from 'react';
import Scenario from './Scenario';
import "./scenario.css";

import {
    Row, Col, Button
} from 'reactstrap';

class StartupScenario {
    static getScenario(scenario: string): JSX.Element {
        switch(scenario) {
            case "sc0":
                return (
                    <Fragment>
                        <br/>
                        <ul style={{fontSize: "90%", paddingLeft: "20px"}}>
                        <span>{Scenario.sc0.l1}</span><br/>
                        <span>{Scenario.sc0.l2}</span><br/>
                        <span>{Scenario.sc0.l3}</span><br/>
                        </ul>
                    </Fragment>
                )
            case "sc1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12" className="text-center">
                                {Scenario.sc1.l1}
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col xs="12" className="text-center">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2">
                                    <img alt="" src={process.env.PUBLIC_URL+"/img/scenario/01_req.png"}/><br/>
                                    {Scenario.sc1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc3">
                                    <img alt="" src={process.env.PUBLIC_URL+"/img/scenario/02_renew.png"} /><br/>
                                    {Scenario.sc1.n2}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc4">
                                    <img alt="" src={process.env.PUBLIC_URL+"/img/scenario/03_reissue.png"} /><br/>
                                    {Scenario.sc1.n3}
                                </Button>
                            </Col>
                        </Row>*/}
                        <Row className="scenario-div-bottom">
                            <Col xs="12" className="text-center">
                                <Button color="primary" className="clickAndSendSc custombtnFAQ" value="sc5">
                                    <img alt="" src={process.env.PUBLIC_URL+"/img/scenario/04_pw.png"} /><br/>
                                    {Scenario.sc1.n4}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtnFAQ" value="sc6">
                                    <img alt="" src={process.env.PUBLIC_URL+"/img/scenario/05_copy.png"} /><br/>
                                    {Scenario.sc1.n5}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtnFAQ" value="sc7">
                                    <img alt="" src={process.env.PUBLIC_URL+"/img/scenario/06_copyphone.png"} /><br/>
                                    {Scenario.sc1.n6}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                )
            case "sc2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1">
                                    {Scenario.sc2.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a2">
                                    {Scenario.sc2.n2}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a3">
                                    {Scenario.sc2.n3}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            // 인증서 신청의 서브 시나리오
            case "sc2a1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1.l1}<br/>
                                {Scenario.sc2a1.l2}<br/>
                                {Scenario.sc2a1.l3}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1">
                                    {Scenario.sc2a1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b2">
                                    {Scenario.sc2a1.n2}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b3">
                                    {Scenario.sc2a1.n3}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b1.l1}<br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1">
                                    {Scenario.sc2a1b1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2">
                                    {Scenario.sc2a1b1.n2}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1.l2}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1d1">
                                    {Scenario.sc2a1b1c1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1d2">
                                    {Scenario.sc2a1b1c1.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1d1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1d1.l1}<br/>
                                {Scenario.sc2a1b1c1d1.l2}<br/>
                                {Scenario.sc2a1b1c1d1.l3}<br/>
                                {Scenario.sc2a1b1c1d1.l4}<br/>
                                {Scenario.sc2a1b1c1d1.l5}<br/>
                                {Scenario.sc2a1b1c1d1.l6}<br/>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1d2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1d2.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1d2e1">
                                    {Scenario.sc2a1b1c1d2.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1d2e1">
                                    {Scenario.sc2a1b1c1d2.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1d2e1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1d2e1.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1d2e1f1">
                                    {Scenario.sc2a1b1c1d2e1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1d2e1f2">
                                    {Scenario.sc2a1b1c1d2e1.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1d2e1f1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1d2e1f1.l1}<br/>
                                {Scenario.sc2a1b1c1d2e1f1.l2}<br/>
                                {Scenario.sc2a1b1c1d2e1f1.l3}<br/>
                                {Scenario.sc2a1b1c1d2e1f1.l4}<br/>
                                {Scenario.sc2a1b1c1d2e1f1.l5}<br/>
                                {Scenario.sc2a1b1c1d2e1f1.l6}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1d2e1f2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1d2e1f2.l1}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l2}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l3}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l4}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l5}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l6}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l7}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l8}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l9}<br/>
                                {Scenario.sc2a1b1c1d2e1f2.l10}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c1d2e2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c1d2e2.l1}<br/>
                                {Scenario.sc2a1b1c1d2e2.l2}<br/>
                                {Scenario.sc2a1b1c1d2e2.l3}<br/>
                                {Scenario.sc2a1b1c1d2e2.l4}<br/>
                                {Scenario.sc2a1b1c1d2e2.l5}<br/>
                                {Scenario.sc2a1b1c1d2e2.l6}<br/>
                                {Scenario.sc2a1b1c1d2e2.l7}<br/>
                                {Scenario.sc2a1b1c1d2e2.l8}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b1c2.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2d1">
                                    {Scenario.sc2a1b1c2.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2d2">
                                    {Scenario.sc2a1b1c2.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c2d1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c2d1.l1}<br/>
                                {Scenario.sc2a1b1c2d1.l2}<br/>
                                {Scenario.sc2a1b1c2d1.l3}<br/>
                                {Scenario.sc2a1b1c2d1.l4}<br/>
                                {Scenario.sc2a1b1c2d1.l5}<br/>
                                {Scenario.sc2a1b1c2d1.l6}<br/>
                                {Scenario.sc2a1b1c2d1.l7}<br/>
                                {Scenario.sc2a1b1c2d1.l8}<br/>
                                {Scenario.sc2a1b1c2d1.l9}<br/>
                                {Scenario.sc2a1b1c2d1.l10}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c2d2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b1c2d2.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2d2e1">
                                    {Scenario.sc2a1b1c2d2.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2d2e2">
                                    {Scenario.sc2a1b1c2d2.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c2d2e1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c2d2e1.l1}<br/>
                                {Scenario.sc2a1b1c2d2e1.l2}<br/>
                                {Scenario.sc2a1b1c2d2e1.l3}<br/>
                                {Scenario.sc2a1b1c2d2e1.l4}<br/>
                                {Scenario.sc2a1b1c2d2e1.l5}<br/>
                                {Scenario.sc2a1b1c2d2e1.l6}<br/>
                                {Scenario.sc2a1b1c2d2e1.l7}<br/>
                                {Scenario.sc2a1b1c2d2e1.l8}<br/>
                                {Scenario.sc2a1b1c2d2e1.l9}<br/>
                                {Scenario.sc2a1b1c2d2e1.l10}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b1c2d2e2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b1c2d2e2.l1}<br/>
                                {Scenario.sc2a1b1c2d2e2.l2}<br/>
                                {Scenario.sc2a1b1c2d2e2.l3}<br/>
                                {Scenario.sc2a1b1c2d2e2.l4}<br/>
                                {Scenario.sc2a1b1c2d2e2.l5}<br/>
                                {Scenario.sc2a1b1c2d2e2.l6}<br/>
                                {Scenario.sc2a1b1c2d2e2.l7}<br/>
                                {Scenario.sc2a1b1c2d2e2.l8}<br/>
                                {Scenario.sc2a1b1c2d2e2.l9}<br/>
                                {Scenario.sc2a1b1c2d2e2.l10}<br/>
                                {Scenario.sc2a1b1c2d2e2.l11}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b2.l1}<br/>
                                {Scenario.sc2a1b2.l2}<br/>
                                {Scenario.sc2a1b2.l3}<br/>
                                {Scenario.sc2a1b2.l4}<br/>
                                {Scenario.sc2a1b2.l5}<br/>
                                {Scenario.sc2a1b2.l6}<br/>
                                {Scenario.sc2a1b2.l7}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1">
                                    {Scenario.sc2a1b2.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2">
                                    {Scenario.sc2a1b2.n2}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b2.l8}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a1b3":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a1b3.l1}<br/>
                                {Scenario.sc2a1b3.l2}<br/>
                                {Scenario.sc2a1b3.l3}<br/>
                                {Scenario.sc2a1b3.l4}<br/>
                                {Scenario.sc2a1b3.l5}<br/>
                                {Scenario.sc2a1b3.l6}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c1">
                                    {Scenario.sc2a1b3.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a1b1c2">
                                    {Scenario.sc2a1b3.n2}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a1b3.l7}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a2.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc2a2b1">
                                    {Scenario.sc2a2.n1}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a2b1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc2a2b1.l1}<br/>
                                {Scenario.sc2a2b1.l2}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <a className="btn btn-primary clickAndSendSc custombtn"
                                    target="_blank"
                                    href="http://naver.me/F2cuDPVA">
                                    {Scenario.sc2a2b1.n1}
                                </a>
                            </Col>
                            <Col xs="12">
                                <a className="btn btn-primary clickAndSendSc custombtn"
                                    target="_blank"
                                    href="http://naver.me/GlYIv8D3">
                                    {Scenario.sc2a2b1.n2}
                                </a>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc2a3":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc2a3.l1}<br/>
                                {Scenario.sc2a3.l2}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc3":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc3.l1}<br/>
                                {Scenario.sc3.l2}<br/>
                                {Scenario.sc3.l3}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://crosscert.com/glca/01_3_033.jsp">
                                    {Scenario.sc3.n1}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.signkorea.com/certificate/renew/renew_idx.jsp">
                                    {Scenario.sc3.n2}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.tradesign.net/certificate/renewal">
                                    {Scenario.sc3.n3}
                                </a>
                            </Col>
                            <Col xs="12">
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.yessign.or.kr/home/subIndex/464.do">
                                    {Scenario.sc3.n4}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.signgate.com/renew/stepEntrpsCrtfctCnfirm.sg">
                                    {Scenario.sc3.n5}
                                </a>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc4":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc4.l1}<br/>
                                {Scenario.sc4.l2}<br/>
                                {Scenario.sc4.l3}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://crosscert.com/glca/01_3_022.jsp">
                                    {Scenario.sc4.n1}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.signkorea.com/certificate/issue/reissue/reissue_idx.jsp">
                                    {Scenario.sc4.n2}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.tradesign.net/certificate/reissue">
                                    {Scenario.sc4.n3}
                                </a>
                            </Col>
                            <Col xs="12">
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.yessign.or.kr/user/home/467/onestop/perReissue/jsp/LayOutPage.do">
                                    {Scenario.sc4.n4}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.signgate.com/reissue/stepEntrpsReisue.sg">
                                    {Scenario.sc4.n5}
                                </a>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc5":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc5.l0}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc5a1">
                                    {Scenario.sc5.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc5a2">
                                    {Scenario.sc5.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc5a1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc5a1.l1}<br/>
                                {Scenario.sc5a1.l2}<br/>
                                {Scenario.sc5a1.l3}<br/><br/>
                                {Scenario.sc5a1.l4}<br/>
                                {Scenario.sc5a1.l5}<br/>
                                {Scenario.sc5a1.l6}<br/>
                                {Scenario.sc5a1.l7}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc5a2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc5a2.l1}<br/>
                                {Scenario.sc5a2.l2}<br/>
                                {Scenario.sc5a2.l3}<br/><br/>
                                {Scenario.sc5a2.l4}<br/>
                                {Scenario.sc5a2.l5}<br/>
                                {Scenario.sc5a2.l6}<br/><br/>
                                {Scenario.sc5a2.l7}<br/>
                                {Scenario.sc5a2.l8}<br/>
                                {Scenario.sc5a2.l9}<br/>
                                {Scenario.sc5a2.l10}<br/>
                                {Scenario.sc5a2.l11}<br/>
                                {Scenario.sc5a2.l12}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc6.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a1">
                                    {Scenario.sc6.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a2">
                                    {Scenario.sc6.n2}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a3">
                                    {Scenario.sc6.n3}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc7a2">
                                    {Scenario.sc6.n4}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc6a1.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a1b1">
                                    {Scenario.sc6a1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a1b2">
                                    {Scenario.sc6a1.n2}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a1b3">
                                    {Scenario.sc6a1.n3}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc7a1">
                                    {Scenario.sc6a1.n4}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a1b1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc6a1b1.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a1b1c1">
                                    {Scenario.sc6a1b1.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a1b1c2">
                                    {Scenario.sc6a1b1.n2}
                                </Button>
                            </Col>                                
                        </Row>
                    </Fragment>
                );
            case "sc6a1b1c1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a1b1c1.l1}<br/>
                                {Scenario.sc6a1b1c1.l2}<br/>
                                {Scenario.sc6a1b1c1.l3}<br/>
                                {Scenario.sc6a1b1c1.l4}<br/>
                                {Scenario.sc6a1b1c1.l5}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a1b1c2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a1b1c2.l1}<br/><br/>
                                {Scenario.sc6a1b1c2.l2}<br/>
                                {Scenario.sc6a1b1c2.l3}<br/>
                                {Scenario.sc6a1b1c2.l4}<br/>
                                {Scenario.sc6a1b1c2.l5}<br/>
                                {Scenario.sc6a1b1c2.l6}<br/><br/>
                                {Scenario.sc6a1b1c2.l7}<br/>
                                {Scenario.sc6a1b1c2.l8}<br/>
                                {Scenario.sc6a1b1c2.l9}<br/>
                                {Scenario.sc6a1b1c2.l10}<br/>
                                {Scenario.sc6a1b1c2.l11}<br/><br/>
                                {Scenario.sc6a1b1c2.l12}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a1b2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a1b2.l1}<br/>
                                {Scenario.sc6a1b2.l2}<br/>
                                {Scenario.sc6a1b2.l3}<br/>
                                {Scenario.sc6a1b2.l4}<br/><br/>
                                {Scenario.sc6a1b2.l5}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a1b3":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a1b3.l1}<br/>
                                {Scenario.sc6a1b3.l2}<br/>
                                {Scenario.sc6a1b3.l3}<br/>
                                {Scenario.sc6a1b3.l4}<br/><br/>
                                {Scenario.sc6a1b3.l5}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc6a2.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a2b1">
                                    {Scenario.sc6a2.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a2b2">
                                    {Scenario.sc6a2.n2}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc6a2b3">
                                    {Scenario.sc6a2.n3}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc7a1">
                                    {Scenario.sc6a2.n4}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
            );
            case "sc6a2b1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a2b1.l1}<br/>
                                {Scenario.sc6a2b1.l2}<br/>
                                {Scenario.sc6a2b1.l3}<br/>
                                {Scenario.sc6a2b1.l4}<br/><br/>
                                {Scenario.sc6a2b1.l5}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a2b2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a2b2.l1}<br/>
                                {Scenario.sc6a2b2.l2}<br/>
                                {Scenario.sc6a2b2.l3}<br/>
                                {Scenario.sc6a2b2.l4}<br/><br/>
                                {Scenario.sc6a2b2.l5}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a2b3":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a2b3.l1}<br/>
                                {Scenario.sc6a2b3.l2}<br/>
                                {Scenario.sc6a2b3.l3}<br/>
                                {Scenario.sc6a2b3.l4}<br/><br/>
                                {Scenario.sc6a2b3.l5}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc6a3":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc6a3.l1}<br/>
                                {Scenario.sc6a3.l2}<br/>
                                {Scenario.sc6a3.l3}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc7":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc7.l1}
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc7a1">
                                    {Scenario.sc7.n1}
                                </Button>
                                <Button color="primary" className="clickAndSendSc custombtn" value="sc7a2">
                                    {Scenario.sc7.n2}
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc7a1":
                return (
                    <Fragment>
                        <Row className="scenario-div-top">
                            <Col xs="12">
                                {Scenario.sc7a1.l1}<br/>
                            {/*</Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://play.google.com/store/apps/details?id=com.crosscert.android&hl=ko">
                                    {Scenario.sc7a1.n1}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://apps.apple.com/kr/app/%ED%86%B5%ED%95%A9%EC%9D%B8%EC%A6%9D%EC%84%BC%ED%84%B0-%EA%B3%B5%EC%9D%B8%EC%9D%B8%EC%A6%9D%EC%84%BC%ED%84%B0/id426081742">
                                    {Scenario.sc7a1.n2}
                                </a>
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">*/}
                                {Scenario.sc7a1.l2}<br/>
                                {Scenario.sc7a1.l3}<br/>
                                {Scenario.sc7a1.l4}<br/>
                                {Scenario.sc7a1.l5}<br/>
                                {Scenario.sc7a1.l6}<br/>
                                {Scenario.sc7a1.l7}<br/><br/>
                                {Scenario.sc7a1.l8}
                            </Col>
                        </Row>
                    </Fragment>
                );
            case "sc7a2":
                return (
                    <Fragment>
                        <Row className="scenario-div-top scenario-div-bottom">
                            <Col xs="12">
                                {Scenario.sc7a2.l1}<br/>
                            {/*</Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://play.google.com/store/apps/details?id=com.crosscert.android&hl=ko">
                                    {Scenario.sc7a2.n1}
                                </a>
                                <a className="btn btn-primary custombtn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://apps.apple.com/kr/app/%ED%86%B5%ED%95%A9%EC%9D%B8%EC%A6%9D%EC%84%BC%ED%84%B0-%EA%B3%B5%EC%9D%B8%EC%9D%B8%EC%A6%9D%EC%84%BC%ED%84%B0/id426081742">
                                    {Scenario.sc7a2.n2}
                                </a>
                            </Col>
                        </Row>
                        <Row className="scenario-div-bottom">
                            <Col xs="12">>*/}
                                {Scenario.sc7a2.l2}<br/>
                                {Scenario.sc7a2.l3}<br/>
                                {Scenario.sc7a2.l4}<br/>
                                {Scenario.sc7a2.l5}<br/>
                                {Scenario.sc7a2.l6}<br/>
                                {Scenario.sc7a2.l7}<br/><br/>
                                {Scenario.sc7a2.l8}
                            </Col>
                        </Row>
                    </Fragment>
                );
            default:
                return <div></div>;
        }
    }
}

export default StartupScenario;