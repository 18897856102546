// React base
import React, {Component, Fragment} from 'react';
import ReactDomServer from 'react-dom/server';
import { RouteComponentProps } from 'react-router-dom';

// Presenters & Pages
import AppConnPresenter from './AppConnPresenter';
import AppPresenter from './AppPresenter';
import PHeader from '../PHeader/PHeader';

// Data
import Const from '../../Const';
import StartupScenario from './scenario/StartupScenario';

// Redux - 초기 개발 상태와 달라진 현 시점에서 redux는 미사용이지만
// 추후 영구 저장소가 필요할 수 있으므로 개발된 사항 그대로 유지합니다
import { StoreState } from '../../Redux/reducer';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { actionCreator } from '../../Redux/action';
import { connect } from 'react-redux';

// ChatApp API
import ChatBotApi from '../../api/ChatBotApi';
import ChatMessage from '../../api/ChatMessage';
import {CHAT_SELF, CHAT_RTN, CHAT_NOTI} from '../../api/ChatType';

interface IMatchProps {
    type: string
}

interface State {
    appTitle: string
}

interface Props {
    firstrun: boolean,
    showHowto: boolean,
    Actions: typeof actionCreator
}

/**
 * 추후 SSL 추가가 필요해지는 경우
 * 1. 웹 클라이언트에서는 url에 있는 type을 활용해주세요
 *    (props.match.params.type)
 * 2. 8088 서버를 수정하여 각 케이스별로
 *    다른 ChatScript를 바라보도록 해야 합니다
 */

class AppContainer extends Component<RouteComponentProps<IMatchProps> & Props> {
    textRef = React.createRef<HTMLInputElement>();
    chatBotApi: ChatBotApi;

    state: State = {
        appTitle: "AI Uni Bot"
    }

    constructor(props: RouteComponentProps<IMatchProps> & Props) {
        super(props);
        this.onPageLoad = this.onPageLoad.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.sendByTouch = this.sendByTouch.bind(this);

        this.chatBotApi = new ChatBotApi(Const.serverurl+":"+Const.serverport, this.updatePage);
        this.chatBotApi.initialize(Const.join, this.updatePage);
        this.chatBotApi.setAdditionalMessage("<br/><button class='btn-primary clickAndSend custombtn'>만족해요</button><button class='btn-primary clickAndSend custombtn'>아쉬워요</button>");

        const type = props.match.params.type;
        switch(type) {
            case "cert":
                this.state = {
                    appTitle: "공인인증서 챗봇"
                }
                break;
            case "ssl":
                this.state = {
                    appTitle: "SSL인증서 챗봇"
                }
                break;
        }
    }

    // React 강제 갱신
    updatePage() {
        this.setState({}, () => {
            this.updateButtonEvt();
        });
    }
    
    componentDidMount() {
        this.onPageLoad();

        // 여기서 체크
        if(this.props.firstrun) {
            // 사용법 보이기
            this.props.Actions.showHowto();
        }
    }

    UNSAFE_componentWillReceiveProps() {
        this.updateButtonEvt();
    }

    // Props가 업데이트 된 이후 각 버튼들의 동작을 정의
    updateButtonEvt() {
        const self = this;
        this.setState({}, () => {
            const els = document.getElementsByClassName('clickAndSend');
            Array.prototype.forEach.call(els, (e) => {
                if(e.getAttribute("listener") !== "yes") {
                    e.addEventListener('click', (e2:React.MouseEvent<HTMLButtonElement>) => {
                        e2.currentTarget.blur();
                        e2.preventDefault();
                        self.clickAndSend(e2.currentTarget.innerText);
                    });
                    e.setAttribute("listener", "yes");
                }
            });

            // 기본 시나리오에 대해 클릭 이벤트 추가
            const elsSC = document.getElementsByClassName('clickAndSendSc');
            Array.prototype.forEach.call(elsSC, (e) => {
                if(e.getAttribute("listener") !== "yes") {
                    e.addEventListener('click', (e2:React.MouseEvent<HTMLButtonElement>) => {
                        e2.currentTarget.blur();
                        e2.preventDefault();
                        self.clickAndSendSc(e2.currentTarget.innerText, e2.currentTarget.value);
                    });
                    e.setAttribute("listener", "yes");
                }
            });

            self.hideLoadAndShowContent();
        });
    }

    // 페이지 사이트 최초 접근 시 시작 메시지를 띄워줌 역할
    onPageLoad() {
        // 첫 구동 시 기본 시나리오 메시지를 추가
        this.chatBotApi.chatlog.push(
            new ChatMessage(ReactDomServer.renderToString(StartupScenario.getScenario("sc0")), CHAT_NOTI));
        this.chatBotApi.chatlog.push(
            new ChatMessage(ReactDomServer.renderToString(StartupScenario.getScenario("sc1")), CHAT_NOTI));
    }

    // 엔터키를 눌러서 메시지를 보내는 경우의 처리
    onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter') {
            const msg = e.currentTarget.value;

            if(msg !== "") {
                e.currentTarget.value = "";

                // Input message into chatlog and send to server
                this.chatBotApi.sendMessage(msg);
                this.scrollToBottom();
            }
        }
    }

    scrollToBottom() {
        this.setState({}, function() {
            document.getElementById("msgbox")!.scrollTop = document.getElementById("msgbox")!.scrollHeight;
        });
    }

    // 보내기 버튼을 눌러서 메시지를 보내는 경우의 처리
    sendByTouch = (msginp: string) => {
        if(msginp) {
            const msg = msginp;
            if(msg !== null && msg !== "") {
                this.textRef.current!.value = "";

                // Input message into chatlog and send to server
                this.chatBotApi.sendMessage(msg);
                this.scrollToBottom();
            }
        }
    }

    /*setStompClient = (client: any) => {
        this.clientRef = client;
    }*/

    clickAndSend(res: string) {
        // 기존 버튼들 다 삭제
        const els:HTMLCollectionOf<HTMLButtonElement> = document.getElementsByClassName('clickAndSend') as HTMLCollectionOf<HTMLButtonElement>;
        for(let i = 0; i < els.length; i++) {
            els.item(i)!.disabled = true
        }

        // Input message into chatlog and send to server
        this.chatBotApi.sendMessage(res);
    }

    clickAndSendSc(currentText: string, nextSc: string) {
        const self = this;

        // 사용자가 선택한 항목을 화면에 먼저 표시하도록 추가
        this.chatBotApi.chatlog.push(new ChatMessage(currentText, CHAT_SELF));
        
        // res 값으로 다시 채팅을 치는 것으로 처리
        this.chatBotApi.chatlog.push(
            new ChatMessage(ReactDomServer.renderToString(StartupScenario.getScenario(nextSc)), CHAT_RTN));
        this.scrollToBottom();
        this.updateButtonEvt();
    }

    hideLoadAndShowContent() {
        // 2초뒤에 로딩을 없애고 실제 내용을 보이게 함
        const self = this;
        setTimeout(function() {
            const loads = document.getElementsByClassName("ansload");
            const cont = document.getElementsByClassName("anscont");
            for(let i = 0; i < loads.length; i++) {
                if(loads[i])
                    loads[i].parentNode!!.removeChild(loads[i]);
            }

            for(let i = 0; i < cont.length; i++) {
                if((cont[i] as HTMLElement).style.display === "none")
                    (cont[i] as HTMLElement).style.display = "block";
            }
            self.scrollToBottom();
        }, 2000);
    }
    
    render() {
        if(this.chatBotApi.connect) {
            return (
                <Fragment>
                    <PHeader title={this.state.appTitle} />
                    <AppPresenter
                        chatlog={this.chatBotApi.chatlog}
                        onKeyDown={this.onKeyDown}
                        sendByTouch={this.sendByTouch}
                        inputRef={this.textRef}
                    />
                </Fragment>
            )
        }   
        else {
            console.log("Cannot connect to server");
            return (
                <Fragment>
                    <PHeader title={this.state.appTitle} />
                    <AppConnPresenter />
                </Fragment>
            )
        }
    }
}

const mapStateToProps = (state: StoreState) => ({
    firstrun: state.tokenReducer.firstrun,
    showHowto: state.tokenReducer.showHowto
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    Actions: bindActionCreators(actionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);