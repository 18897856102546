import ChatMessage from "./ChatMessage";
import StompComm from './StompComm';
import getDevice from './UserAgent'
import { CHAT_RTN, CHAT_SELF } from "./ChatType";

class ChatBotApi {
    //----- Variable Area -----
    // Class variable init (prior state in React Component)
    public connect: boolean;
    public chatlog: Array<ChatMessage>;

    private session: string;
    private ip: string;
    private degree: number;
    private additionalMessage: string;

    private stompEndpoint: string;
    private destUrl: string;

    private updatePage: () => void;

    // init url
    private initUrl: string;

    /**
     * Stomp client with SockJS
     */
    stompClient: StompComm | null = null;

    constructor(initUrl: string, updatePage: any) {
        this.connect = false;
        this.session = "";
        this.ip = "";
        this.degree = 0;
        this.chatlog = new Array<ChatMessage>();

        this.additionalMessage = "";
        this.stompEndpoint = "/chat";
        this.destUrl = "/app/send/";

        this.initUrl = initUrl;
        this.updatePage = updatePage;

        this.updateChatLog = this.updateChatLog.bind(this);
    }

    // Initialize connection
    // laterWork: page refresh methods such as setState in ReactJS
    initialize(joinSubUrl: string, callbackAfterFetch: any) {
        this.timeoutableFetch(this.initUrl+joinSubUrl, callbackAfterFetch);
    }

    // Timeoutable Fetch (default 5s)
    private timeoutableFetch(url: string, callbackAfterFetch: any, options: any = {method: 'GET'}) {
        let { timeout = 5000, ...rest } = options;
        if (rest.signal) throw new Error("Signal not supported in timeoutable fetch");
        const controller = new AbortController();
        const { signal } = controller;
        return new Promise((res, reject) => {
            const timer = setTimeout(() => {
                reject(new Error("Timeout for Promise"));
                controller.abort();
            }, timeout);
            fetch(url, { signal, ...rest })
            .finally(() => clearTimeout(timer))
            .then((res) => {
                res.json()
                .then((json) => {
                    // set user session and ip address
                    this.session = json.session;
                    this.ip = json.ip;
                    this.connect = true;

                    this.stompClient = new StompComm(this.initUrl+this.stompEndpoint, this.session, this.updateChatLog);
                })
                .then(callbackAfterFetch)
            }, reject);
        });
    }

    // parse json msg from server
    /**
     * 
     * @param msg response message
     * @param additionalMessage If satisfiction is True from server, it adds additional message
     */
    updateChatLog(msg: any) {
        const jsonmsg = JSON.parse(msg.body);
        let message: string = jsonmsg.msg;
        if(jsonmsg.satis === "T") {
            // add safisfication question
            message += this.additionalMessage;
        }
        this.chatlog.push(new ChatMessage(message, CHAT_RTN));
        this.updatePage();
    }

    // Input message into chatlog and send to server
    sendMessage(msg: string) {
        this.chatlog.push(new ChatMessage(msg, CHAT_SELF));
        const s = {name: ".", session: this.session, content: msg, device: getDevice(), ip: this.ip}
        
        if(this.stompClient !== null)
            this.stompClient.client.send(this.destUrl+this.session, {}, JSON.stringify(s));
        else 
            alert("STOMP Client가 연결되지 않았습니다");
    }

    changeStompEndpoint(endpoint: string) {
        this.stompEndpoint = endpoint;
    }

    changeDestinationUrl(destination: string) {
        this.destUrl = destination;
    }

    /**
     * 
     * @param msg Set additional message for satisfiction queries. You can add HTML tags and show them with innerHTML for CHAT_RTN messages.
     */
    setAdditionalMessage(msg: string) {
        this.additionalMessage = msg;
    }
}

export default ChatBotApi;