import React, {Component} from 'react';

import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Container
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faMicrophone } from '@fortawesome/free-solid-svg-icons';

import './chatbox.css';
import "./component/input/textinput.css";

class AppConnPresenter extends Component {
    render() {
        return (
            <Container fluid={true} className="chat-container">
                <Row className="chat-row">
                    <Col xs="12" className="chat-col p-0">
                        <Card>
                            <CardBody id="msgbox"
                                className="d-flex flex-column msg_card_body">
                                <div className="my-auto text-center">
                                    서버에 연결하고 있습니다.<br/>
                                    접속이 되지 않는다면 새로고침을 시도하거나 네트워크 상태를 확인해주세요.<br/>
                                    혹은 아래의 연락처로 문의를 주시기 바랍니다.
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className="input-group">
                                    <span className="input-group-text mic_btn">
                                        <FontAwesomeIcon icon={faMicrophone} />
                                    </span>
                                    <input
                                        disabled
                                        id="inputMessage"
                                        className="form-control type_msg"
                                        placeholder="궁금한 것을 입력해보세요" />
                                    <span className="input-group-text send_btn">
                                        <FontAwesomeIcon icon={faLocationArrow} />
                                    </span>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AppConnPresenter;