function getDevice(): string {
    const agent = navigator.userAgent;

    // Detection: PC Browser (include mac), Mobile, Etc
    if(agent.indexOf("Mobi") > -1) {
        return "M";
    }
    else if(agent.match(/(Firefox|Seamonkey|Chrome|Chromium|Safari|Opera|OPR|Edge|MSIE)/)) {
        return "B";
    }
    else {
        return "E";
    }
}

export default getDevice;