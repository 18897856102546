const scenario = {
    // 시작하는 페이지
    startup: {
        l0: "상담하고 싶은 항목을 선택해주세요",
        n1: "공인인증서",
        n2: "민원24",
        n3: "홈텍스"
    },
    // 안내
    sc0: {
        l0: "※ 안내사항",
        l1: "UniBot이 2020년 11월 30일 부로 서비스를 종료합니다.",
        l2: "서비스 종료 후에는 채팅상담이용이 불가능합니다.",
        l3: "그동안 이용해주셔서 감사합니다."
    },
    // 진입
    sc1: {
        l1: "아래에서 원하시는 질문을 선택하세요",
        n1: "인증서 신청",
        n2: "인증서 갱신",
        n3: "인증서 재발급",
        n4: "비밀번호 관리",
        n5: "인증서 복사",
        n6: "스마트폰 복사"
    },
    // 신청
    sc2: {
        l1: "필요하신 인증서의 종류를 선택해주세요",
        n1: "사업자 범용",
        n2: "개인 범용",
        n3: "기타 인증서"
    },
    // 공용 인증서 신청 링크
    sc2link: "인증서 신청하기", // https://m.crosscert.com/apply.html?p=no
    // 신청 - 사업자 범용
    sc2a1: {
        l1: "인증서 신청 시 전자서명법 시행규칙 및 공인인증업무준칙에 의거하여 신청서 및 구비서류를 직접대면하여 제출해야 합니다.",
        l2: "내사방문이 어려우시다면 '찾아가는 서비스' 또는 '방문발급 서비스'를 이용하실 수 있습니다.",
        l3: "단, 사업자 명의의 계좌가 있으시다면 방문 없이 해당 은행을 통해서도 발급이 가능합니다.",
        n1: "내사 신청하기",
        n2: "찾아가는 서비스 신청하기",
        n3: "방문발급 서비스 신청하기"
    },
    // 신청 - 사업자 범용 - 내사
    sc2a1b1: {
        l1: "방문 하시는 분에 따라 필요 서류가 달라집니다. 어느 분이 방문하시나요?",
        n1: "대표자 방문",
        n2: "대리인 방문",
        l2: "단, 대리인은 재직 증명이 가능한 직원만 가능합니다 (대표자 가족 불가)"
    },
    // 신청 - 사업자 범용 - 내사 - 대표자
    sc2a1b1c1: {
        l1: "대표자는 몇 분이신가요?",
        n1: "1인",
        n2: "2인 이상"
    },
    // 신청 - 사업자 범용 - 내사 - 대표자 - 1인
    sc2a1b1c1d1: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 대표자 신분증 앞면 사본 1부",
        l5: "* 공인인증서 신청서에는 대표자 자필 서명이 필요합니다.",
        l6: "* 대표자 신분증 원본도 같이 지참해야 합니다.",
    },
    // 신청 - 사업자 범용 - 내사 - 대표자 - 2인 이상
    sc2a1b1c1d2: {
        l1: "공동 대표인가요 각자 대표인가요?",
        n1: "공동 대표",
        n2: "각자 대표"
    },
    // 신청 - 사업자 범용 - 내사 - 대표자 - 2인 이상 - 공동 대표
    // 이 질문은 공용으로 대체함
    sc2a1b1c1d2e1: {
        l1: "대표자 모두 방문 혹은 서류제출을 하시나요?",
        n1: "모두",
        n2: "일부만"
    },
    // 신청 - 사업자 범용 - 내사 - 대표자 - 2인 이상 - 공동 대표 - 모두 방문
    sc2a1b1c1d2e1f1: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 대표자 전원의 신분증 앞면 사본 1부",
        l5: "* 공인인증서 신청서에는 대표자 모두의 자필 서명이 필요합니다.",
        l6: "* 대표자 전원의 신분증 원본도 같이 지참해야 합니다.",
    },
    // 신청 - 사업자 범용 - 내사 - 대표자 - 2인 이상 - 공동 대표 - 일부 방문
    sc2a1b1c1d2e1f2: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 부재중인 대표자의 위임장",
        l5: "4. 부재중인 대표자의 사용인감 원본",
        l6: "5. 제출하는 대표자의 신분증 앞면 사본 1부",
        l7: "* 공인인증서 신청서에는 대표자 모두의 자필 서명이 필요합니다.",
        l8: "* 3의 위임장은 부재중인 대표자의 인감날인으로 서명합니다.",
        l9: "* 4의 사용인감 원본은 6개월 이내에 발급된 서류여야 합니다.",
        l10: "* 제출하는 대표자의 신분증 원본도 같이 지참해야 합니다.",
    },
    // 신청 - 사업자 범용 - 내사 - 대표자 - 2인 이상 - 각자 대표
    sc2a1b1c1d2e2: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 방문 예정인 대표자의 신분증 앞면 사본 1부",
        l5: "4. 법인 등기부등본 제출용 1부",
        l6: "* 공인인증서 신청서에는 방문 예정인 대표자의 자필 서명이 필요합니다.",
        l7: "* 방문하는 대표자는 신분증 원본도 같이 지참해야 합니다.",
        l8: "* 4의 등기부등본은 6개월 이내에 발급된 서류여야 합니다.",
    },
    // 신청 - 사업자 범용 - 내사 - 대리인
    sc2a1b1c2: {
        l1: "대표자는 몇 분이신가요?",
        n1: "1인",
        n2: "2인 이상"
    },
    // 신청 - 사업자 범용 - 내사 - 대리인 - 1인
    sc2a1b1c2d1: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부 - 아래에 추가 내용을 포함해야 합니다",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 대표자 개인 인감증명서 원본 1부",
        l5: "4. 대리인 신분증 앞면 사본 1부",
        l6: "* 신청서와 함께 필요한 내용",
        l7: "- 대표자의 개인 인감증명서와 동일한 도장 날인",
        l8: "- 대리인 위임장 작성",
        l9: "* 3의 인감증명서 원본은 6개월 이내에 발급된 서류여야 합니다.",
        l10: "* 대리인은 신분증 원본도 지참해야 합니다."
    },
    // 신청 - 사업자 범용 - 내사 - 대리인 - 2인 이상
    sc2a1b1c2d2: {
        l1: "공동 대표인가요 각자 대표인가요?",
        n1: "공동 대표",
        n2: "각자 대표"
    },
    // 신청 - 사업자 범용 - 내사 - 대리인 - 2인 이상 - 공동대표
    sc2a1b1c2d2e1: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부 - 아래에 추가 내용을 포함해야 합니다",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 대표자 모두의 법인 인감증명서 원본 1부",
        l5: "4. 대리인 신분증 앞면 사본 1부",
        l6: "* 신청서와 함께 필요한 내용",
        l7: "- 대표자 모두의 개인 인감증명서와 동일한 도장 날인",
        l8: "- 대리인 위임장 작성",
        l9: "* 3의 인감증명서 원본은 6개월 이내에 발급된 서류여야 합니다.",
        l10: "* 대리인은 신분증 원본도 지참해야 합니다.",
    },
    // 신청 - 사업자 범용 - 내사 - 대리인 - 2인 이상 - 각자대표
    sc2a1b1c2d2e2: {
        l1: "필요한 서류는 다음과 같습니다.",
        l2: "1. 공인인증서 신청서 원본 1부 - 아래에 추가 내용을 포함해야 합니다",
        l3: "2. 사업자등록증 사본 1부",
        l4: "3. 대표자 모두의 법인 인감증명서 원본 1부",
        l5: "4. 대리인 신분증 앞면 사본 1부",
        l6: "5. 법인 등기부등본 제출용 1부",
        l7: "* 신청서와 함께 필요한 내용",
        l8: "- 대표자 모두의 개인 인감증명서와 동일한 도장 날인",
        l9: "- 대리인 위임장 작성",
        l10: "* 3의 인감증명서 원본과 5의 등기부등본은 6개월 이내에 발급된 서류여야 합니다.",
        l11: "* 대리인은 신분증 원본도 지참해야 합니다.",
    },
    // 이하는 루트는 동일하며 변경되는 내용만 추가 기입
    // 신청 - 사업자 범용 - 찾아가는 서비스
    sc2a1b2: {
        l1: "찾아가는 서비스 (추가비용 4300원)",
        l2: "1. 고객님께서 서류접수처에 방문하실 필요가 없습니다.",
        l3: "2. 온라인 신청 시 찾아가는 서비스를 선택 후 결제하시고, 서류를 준비한 뒤 FAX를 보내고 연락주시면 서비스 직원에게 접수가 됩니다.",
        l4: "3. 서비스 직원이 직접 고객님께 방문하여 신원 확인 및 서류 수거 후 복귀하면 인증서를 발급할 수 있습니다. (영업일 기준 6~9일 소요)",
        l5: "4. 서류수거시 제공받은 우편물에 기재된 승인번호와 서류도착 후 SMS로 발송해드리는 조회용 번호로 인증서를 발급받습니다.",
        l6: "서류를 제출하시는 분에 따라 필요한 서류가 달라집니다.",
        l7: "어느 분이 제출하시나요?",
        n1: "대표자 제출",
        n2: "대리인 제출",
        l8: "단, 대리인은 재직 증명이 가능한 직원만 가능합니다 (대표자 가족 불가)"
    },
    // 신청 - 사업자 범용 - 방문발급 서비스
    sc2a1b3: {
        l1: "방문발급 서비스 (추가비용 20,000원)",
        l2: "1. 고객님께서 서류접수처에 방문하실 필요가 없습니다",
        l3: "2. 온라인 신청시 방문발급 서비스를 선택 후 결제하시고, 서류를 준비해 주시면 서비스 직원에게 접수가 됩니다.",
        l4: "3. 서비스 직원이 직접 방문하여 본인 확인 및 서류 확인 후 발급까지 바로 해드립니다.",
        l5: "서류를 제출하시는 분에 따라 필요한 서류가 달라집니다.",
        l6: "어느 분이 제출하시나요?",
        n1: "대표자 제출",
        n2: "대리인 제출",
        l7: "단, 대리인은 재직 증명이 가능한 직원만 가능합니다 (대표자 가족 불가)"
    },
    // 신청 - 개인범용
    sc2a2: {
        l1: "개인범용 공인인증서는 은행을 통해 계좌개설과 인터넷뱅킹을 신청하신 후 해당 은행에서 발급 받으시거나 인증기관으로 방문하셔야 합니다.",
        n1: "한국전자인증에서 신청하기"
    },
    // 신청 - 개인범용 - 한국전자인증에서 신청하기
    sc2a2b1: {
        l1: "개인용 인증서 발급 시에 반드시 본인이 대면확인을 해야 합니다. (미성년자 포함)",
        l2: "서류 제출은 한국전자인증 본사와 수원 센터에서 가능합니다.",
        n1: "본사 위치 확인하기",
        n2: "수원센터 위치 확인하기"
    },
    // 신청 - 기타인증서
    sc2a3: {
        l1: "특수 목적용 인증서는 특정 사이트에서만 사용 가능한 인증서로 이용하시고자 하는 사이트의 공인인증서 신청 페이지에서 신청이 가능합니다.",
        l2: "인증서를 사용하실 해당 사이트에 문의하십시오."
    },
    // 갱신
    sc3: {
        l1: "공인인증서의 유효기간이 지난 경우 인증서 갱신을 진행하실 수 없으며 인증서 신규 신청을 진행해야 합니다.",
        l2: "결제 후 갱신을 못하고 만료된 경우에는 발급 기관으로 문의해야 합니다.",
        l3: "단, 은행을 통해 발급받은 인증서의 경우엔느 해당 은행 홈페이지에서 갱신해야 합니다.",
        n1: "한국전자인증", // https://crosscert.com/glca/01_3_033.jsp
        n2: "코스콤", // https://www.signkorea.com/certificate/renew/renew_idx.jsp
        n3: "무역정보통신", // https://www.tradesign.net/certificate/renewal
        n4: "YESSIGN", // https://www.yessign.or.kr/home/subIndex/464.do
        n5: "한국정보인증" // https://www.signgate.com/renew/stepEntrpsCrtfctCnfirm.sg
    },
    // 재발급
    sc4: {
        l1: "인증서 재발급이란 인증서 분실, 비밀번호 분실, 인증서 손상 등의 이유로 기존 인증서와 동일한 인증서를 다시 발급받는 것입니다.",
        l2: "재발급은 기존 인증설르 받은 기관에서 신청해야 하며 서류 제출이 필요합니다. 재발급된 인증서는 기존 인증서와 유효기간이 동일합니다.",
        l3: "단, 은행을 통해 발급받은 인증서인 경우에는 해당 은행 홈페이지에서 재발급이 가능합니다.",
        n1: "한국전자인증", // https://crosscert.com/glca/01_3_022.jsp
        n2: "코스콤", // https://www.signkorea.com/certificate/issue/reissue/reissue_idx.jsp
        n3: "무역정보통신", // https://www.tradesign.net/certificate/reissue
        n4: "YESSIGN", // https://www.yessign.or.kr/user/home/467/onestop/perReissue/jsp/LayOutPage.do
        n5: "한국정보인증" // https://www.signgate.com/reissue/stepEntrpsReisue.sg
    },
    // 비밀번호 관리
    sc5: {
        l0: "비밀번호 관리",
        n1: "비밀번호 찾기",
        n2: "비밀번호 변경"
    },
    sc5a1: {
        l1: "인증서의 비밀번호는 어디에도 저장되지 않으며 오직 고객님만 설정이 가능합니다.",
        l2: "비밀번호 확인에 횟수 제한은 없으니 비밀번호를 확인해보시기 바랍니다.",
        l3: "만약 비밀번호를 찾지 못하셨다면 발급기관을 통해 인증서를 재발급 받으셔야 합니다.",
        l4: "비밀번호 확인 방법",
        l5: "1. 한국전자인증 홈페이지 접속",
        l6: "2. 홈페이지 메인 화면에서 [5. 관리] 메뉴 클릭",
        l7: "3. 메뉴 중 [7. 공인인증서 비밀번호 확인] 이용"
    },
    sc5a2: {
        l1: "1. 한국전자인증 홈페이지 접속",
        l2: "2. 홈페이지 메인 화면에서 [5. 관리] 메뉴 선택",
        l3: "3. 메뉴에서 [8. 공인인증서 비밀번호 변경] 클릭",
        l4: "주의사항",
        l5: "- 인증서의 비밀번호는 어디에도 저장되지 않으며 오직 고객님만 설정 가능합니다.",
        l6: "- 아래의 비밀번호 생성 규칙에 따라 생성해주시기 바랍니다.",
        l7: "비밀번호 생성 규칙",
        l8: "- 10자리 이상",
        l9: "- 영문/숫자/특수문자를 모두 포함",
        l10: "- 동일문자 반복 금지",
        l11: "- 연속된 3개 이상의 문자 사용 금지",
        l12: "- 허용되지 않는 일부 특수문자 사용 금지 (', \", \\, |)",
    },
    // 인증서 복사
    sc6: {
        l1: "인증서가 어디에 저장되어 있으신가요?",
        n1: "PC",
        n2: "이동식 디스크",
        n3: "보안토큰",
        n4: "스마트폰"
    },
    // 인증서 복사 - PC
    sc6a1: {
        l1: "어디로 복사하실건가요?",
        n1: "다른 PC",
        n2: "이동식 디스크",
        n3: "보안토큰",
        n4: "스마트폰"
    },
    // 인증서 복사 - PC - 다른 PC
    sc6a1b1: {
        l1: "어떤 매채를 이용해서 복사하실건가요?",
        n1: "이동식 디스크",
        n2: "이메일 (PFX)"
    },
    // 인증서 복사 - PC - 다른 PC - 이동식 디스크
    sc6a1b1c1: {
        l1: "PC에 있는 인증서를 이동식 디스크로 복사한 뒤 다른 PC에 동일한 방법으로 복사할 수 있습니다.",
        l2: "1. 한국전자인증 홈페이지 접속",
        l3: "2. 홈페이지 메인에서 [5. 관리] 메뉴 선택",
        l4: "3. 메뉴 중 [1. 공인인증서 복사] 메뉴를 통해 복사",
        l5: "자세한 복사 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다."
    },
    // 인증서 복사 - PC - 다른 PC - 이메일 (PFX)
    sc6a1b1c2: {
        l1: "PC에 있는 인증서를 PFX 파일로 생성하여 이메일로 전송한 뒤 다른 PC에서 PFX를 가져오는 방식으로 진행됩니다.",
        l2: "- 내보내기 -",
        l3: "1. 한국전자인증 홈페이지 접속",
        l4: "2. 메인화면의 [5. 관리] 메뉴 선택",
        l5: "3. 메뉴의 [10. 공인인증서 내보내기] 에서 PFX 생성",
        l6: "4. 생성된 파일을 이메일로 전송",
        l7: "- 가져오기 -",
        l8: "1. 한국전자인증 홈페이지 접속",
        l9: "2. 홈페이지 메인에서 [5. 관리] 메뉴 선택",
        l10: "3. 메뉴의 [9. 공인인증서 가져오기] 를 통해 PFX 파일 가져오기",
        l11: "4. 저장 완료",
        l12: "자세한 내보내기/가져오기 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다"
    },
    // 인증서 복사 - PC - 이동식 디스크
    sc6a1b2: {
        l1: "1. 한국전자인증 홈페이지 접속",
        l2: "2. 메인에서 [5. 관리] 메뉴 선택",
        l3: "3. 메뉴에서 [1. 공인인증서 복사] 메뉴를 선택하여 복사 가능",
        l4: "(저장매체에서 이동식 디스크 선택)",
        l5: "자세한 복사 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다"
    },
    // 인증서 복사 - PC - 토큰
    sc6a1b3: {
        l1: "1. 한국전자인증 홈페이지 접속",
        l2: "2. 홈페이지 메인에서 [5. 관리] 메뉴 선택",
        l3: "3. 메뉴에서 [1. 공인인증서 복사] 메뉴를 선택하여 복사 가능",
        l4: "(저장매체에서 보안토큰 선택)",
        l5: "자세한 복사 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다."
    },
    // 인증서 복사 - PC - 스마트폰 (sc6a1b4 => sc7a1)
    // 인증서 복사 - 이동식 디스크
    sc6a2: {
        l1: "어디로 복사하시나요?",
        n1: "PC",
        n2: "다른 이동식 디스크",
        n3: "토큰",
        n4: "스마트폰"
    },
    // 인증서 복사 - 이동식 디스크 - PC
    sc6a2b1: {
        l1: "1. 한국전자인증 홈페이지 접속",
        l2: "2. 메인에서 [5. 관리] 메뉴 선택",
        l3: "3. 메뉴에서 [1. 공인인증서 복사]를 선택하여 복사 가능",
        l4: "(저장매체에서 하드디스크 선택)",
        l5: "자세한 복사 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다"
    },
    // 인증서 복사 - 이동식 디스크 - 다른 이동식 디스크
    sc6a2b2: {
        l1: "1. 한국전자인증 홈페이지 접속",
        l2: "2. 메인에서 [5. 관리] 메뉴 선택",
        l3: "3. 메뉴에서 [1. 공인인증서 복사]를 선택하여 복사 가능",
        l4: "(저장매체에서 이동식 디스크 선택)",
        l5: "자세한 복사 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다"
    },
    // 인증서 복사 - 이동식 디스크 - 토큰
    sc6a2b3: {
        l1: "1. 한국전자인증 홈페이지 접속",
        l2: "2. 메인에서 [5. 관리] 메뉴 선택",
        l3: "3. 메뉴에서 [1. 공인인증서 복사]를 선택하여 복사 가능",
        l4: "(저장매체에서 보안토큰 선택)",
        l5: "자세한 복사 방법은 홈페이지 내 자세히 보기를 통해 확인 가능합니다"
    },
    // 인증서 복사 - 이동식 디스크 - 스마트폰 (sc6a2b4 => sc7a1)
    // 인증서 복사 - 토큰
    sc6a3: {
        l1: "보안토큰에 저장된 인증서는 다른 곳으로 복사할 수 없습니다.",
        l2: "백업된 인증서가 있다면 백업된 인증서를 사용하시고",
        l3: "백업된 인증서가 없다면 재발급 후 백업 및 보안토큰에 인증서를 저장하여 사용하시면 됩니다."
    },
    // 인증서 복사 - 스마트폰 (sc6a4 => sc7a2)
    // 스마트폰 복사
    sc7: {
        l1: "어디로 복사하시나요?",
        n1: "PC/이동식 디스크→스마트폰",
        n2: "스마트폰→PC/이동식 디스크"
    },
    sc7a1: {
        l1: "1. 스마트폰에서 한국전자인증 통합인증센터 앱 실행",
        l2: "2. 앱에서 공인인증서 이동 - 인증서 가져오기 선택",
        l3: "3. 화면에 표시되는 승인번호 13자리를 확인",
        l4: "4. PC에서 UniSign 사이트 접속 후 공인인증서 이동 클릭",
        l5: "5. 공인인증서 복사하기 (PC→스마트폰) 클릭 후 13자리 승인번호 입력",
        l6: "6. 인증서 목록에서 복사할 인증서를 선택",
        l7: "7. PC에서 스마트폰으로 복사 완료",
        l8: "* 아이폰 사용자의 경우에는 사용할 앱을 통해 복사해야만 인증서 사용이 가능합니다."
    },
    sc7a2: {
        l1: "1. 스마트폰에서 한국전자인증 통합인증센터 앱 실행",
        l2: "2. 앱에서 공인인증서 이동 - 인증서 내보내기 선택",
        l3: "3. 인증서를 선택하고 비밀번호 입력",
        l4: "4. 화면에 표시되는 승인번호 13자리 확인",
        l5: "5. PC에서 UniSign 사이트 접속 후 공인인증서 이동 클릭",
        l6: "6. 공인인증서 복사하기 (스마트폰→PC) 클릭 후 13자리 승인번호 입력",
        l7: "7. PC에 저장할 매체를 선택하여 복사 완료",
        l8: "* 아이폰 사용자의 경우에는 사용할 앱을 통해 복사해야만 인증서 사용이 가능합니다."
    }
}

export default scenario;