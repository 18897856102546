import React, {Component} from 'react';

import {
    Row,
    Col,
    Container,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { StoreState } from '../../Redux/reducer';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { actionCreator } from '../../Redux/action';
import { connect } from 'react-redux';
import './startup.css';
import HowToPresenter from '../Howto/howtoPresenter';

interface Props {
    firstrun: boolean,
    showHowto: boolean,
    Actions: typeof actionCreator,

    changeAppTitle: (param: string) => void
}


/**
 * 초기 페이지
 */
class Startup extends Component<Props> {

    constructor(props: Props) {
        super(props);
        this.props.Actions.hideHowto();
        

    }

    render() {
        return (
            <Container fluid={true} className="h-100 d-flex flex-column p-0">
                <Row className="catContent">
                    <Col xs="12" className="text-center my-auto">
                        <Row>
                            <Col xs="12" className="text-center txt-startup">
                                UniBot이 2020년 11월 30일 부로 서비스를 종료합니다.
                            </Col>
                            <Col xs="12" className="text-center txt-startup">
                                서비스 종료 후에는 채팅상담이 불가능 합니다.
                            </Col>
                            <Col xs="12" className="text-center txt-startup">
                                이용해주셔서 감사합니다.
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="text-center contact">
                        <a href="mailto:sos@crosscert.com">
                            Contact us
                        </a>
                    </Col>
                </Row>
                <footer className="catFooter">
                    Version 1.0<br/>
                    (c) 2019-2020 Crosscert All rights reserved.
                </footer>
            </Container>
            
        )
    }
}

const mapStateToProps = (state: StoreState) => ({
    firstrun: state.tokenReducer.firstrun,
    showHowto: state.tokenReducer.showHowto
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    Actions: bindActionCreators(actionCreator, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Startup);