import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import "./voiceinput.css";

interface Props {
    micCancel: () => void
}

class VoiceInput extends Component<Props> {
    render() {
        return (
            <Row>
                <Col className="text-center" xs="12">
                    <span className="text-center" style={{padding: "5px", width: "100%"}}>
                        마이크 입력을 받고 있습니다<br/>
                        <img alt="" src={require("./mic.gif")} style={{width: "50px", height: "50px"}} />
                    </span>
                </Col>
                <div style={{position: "absolute", right: "10px"}}>
                    <span onClick={this.props.micCancel} className="micclose_btn">
                        <FontAwesomeIcon icon={faWindowClose} />
                    </span>
                </div>
            </Row>
        )
    }
}

export default VoiceInput;