import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import "./textinput.css";

interface Props {
    micInputWindow: () => void,
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    sendByTouch: (param: string) => void,
    inputRef: React.RefObject<HTMLInputElement>
}

class TextInput extends Component<Props> {
    render() {
        return (
            <div className="input-group">
                <span className="input-group-text mic_btn"
                    onClick={this.props.micInputWindow}>
                    <FontAwesomeIcon icon={faMicrophone} />
                </span>
                <input
                    ref={this.props.inputRef}
                    id="inputMessage"
                    className="form-control type_msg"
                    placeholder="궁금한 것을 입력해보세요"
                    onKeyDown={this.props.onKeyDown} />
                <span className="input-group-text send_btn"
                    onClick={() => this.props.sendByTouch(this.props.inputRef.current!.value)}>
                    <FontAwesomeIcon icon={faLocationArrow} />
                </span>
            </div>
        )
    }
}

export default TextInput;