class Const {
    //static serverurl: string = "https://10.10.10.83";
    static serverurl: string = "https://chatapp.crosscert.com";
    //static serverurl: string = "http://localhost";
    static serverport: number = 8088;
    //8088

    static join: string="/join";
}

export default Const;