import React, {Component, Fragment} from 'react';
import './sidenav.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleLeft, faAngleLeft} from '@fortawesome/free-solid-svg-icons';

import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    ListGroup,
    ListGroupItem
} from 'reactstrap';

import { StoreState } from '../../Redux/reducer';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { actionCreator } from '../../Redux/action';
import HowToPresenter from '../Howto/howtoPresenter';
import { Link } from 'react-router-dom';

interface Props {
    firstrun: boolean,
    showHowto: boolean,
    Actions: typeof actionCreator,

    title: string
}

interface State {
    navExp: boolean
}

class PHeader extends Component<Props, State> {
    state: State = {
        navExp: false
    }

    constructor(props: Props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleHowto = this.toggleHowto.bind(this);
        this.toggleFirstOff = this.toggleFirstOff.bind(this);
    }

    toggleMenu() {
        this.setState({
            navExp: !this.state.navExp
        });
    }

    toggleHowto() {
        this.setState({
            navExp: false
        });

        if(this.props.showHowto) {
            this.props.Actions.hideHowto();
        }
        else {
            this.props.Actions.showHowto();
        }
    }

    toggleFirstOff() {
        this.props.Actions.setFirstRunOff();
        this.toggleHowto();
    }

    render() {
        const self = this;
        return (
            <Fragment>
                <header>
                    <Navbar className="static-top hd_style">
                        <NavbarToggler tag={Link} to="/" className="hd_toggler">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </NavbarToggler>
                        <NavbarBrand>
                            <span className="hd_brand"><b>{this.props.title}</b></span>
                        </NavbarBrand>
                        <Navbar></Navbar>
                    </Navbar>
                </header>

                {/*<HowToPresenter
                    showHowTo={this.props.showHowto}
                    toggleHowto={this.toggleHowto}
                    toggleFirstOff={this.toggleFirstOff}
                />*/}
            </Fragment>
        )
    }
}

const mapStateToProps = (state: StoreState) => ({
    firstrun: state.tokenReducer.firstrun,
    showHowto: state.tokenReducer.showHowto
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    Actions: bindActionCreators(actionCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PHeader);