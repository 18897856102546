import React, {Component, Fragment} from 'react';
import {Route} from 'react-router-dom';
import './App.css'
import './Pages/ChatApp/chatbox.css';

import AppContainer from './Pages/ChatApp/AppContainer';
import Startup from './Pages/Startup/Startup';

/**
 * React-Router를 사용하여 SPA를 구현합니다
 */
class App extends Component<{}> {
    constructor(props: {}) {
        super(props);
    }

    /**
     * 챗봇 페이지는 :type을 사용하여 나눠집니다
     * cert: 공인인증서
     * mw24: 민원24 (미사용)
     * hometax: 홈택스 (미사용)
     */
    render() {
        return (
            <Fragment>
                <Route exact path="/" component={Startup} />
                <Route path="/chat/:type" component={AppContainer} />
            </Fragment>
        )
    }
}

export default App;
